@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .animation-delay-200 {
        animation-delay: 0.2s;
    }

    .animation-delay-400 {
        animation-delay: 0.4s;
    }
}

@layer utilities {
    .ad-preview {
        height: 628px;
        width: 1200px;
    }

    .ad-preview-banner {
        height: 128px;
    }

    .ad-preview-images-section {
        height: 500px;
    }

    .ad-preview-image {
        height: 480px;
    }

    .font-helvetica {
        font-family: "Helvetica", sans-serif;
    }
}

.without-ring {
    @apply outline-transparent ring-transparent focus:outline-transparent focus:ring-0 focus:ring-transparent focus:ring-offset-0;
}

[type="checkbox"]:focus:not(:focus-visible),
[type="radio"]:focus:not(:focus-visible),
[type="text"]:focus:not(:focus-visible),
[type="password"]:focus:not(:focus-visible),
[type="email"]:focus:not(:focus-visible),
button:focus:not(:focus-visible),
select:focus:not(:focus-visible),
textarea:focus:not(:focus-visible) {
  @apply focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0;
}

[type="checkbox"]:focus-visible,
[type="radio"]:focus-visible,
[type="text"]:focus-visible,
[type="password"]:focus-visible,
[type="email"]:focus-visible,
button:focus-visible,
select:focus-visible,
textarea:focus-visible {
  @apply outline-none ring-2 ring-blue-500 ring-offset-2;
}


body {
    @apply min-h-screen overflow-x-hidden bg-gray-50 sm:overflow-hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

input[type="text"] {
    -webkit-appearance: none;
}

.slider-card-featured {
    @apply xl:!min-w-[288px];
}

.slider-card {
    @apply xl:!min-w-[236px];
}

.ais-SearchBox-input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.scrollbar-thin::-webkit-scrollbar {
    width: 2px;
}

.scrollbar-thin::-webkit-scrollbar-track {
    background-color: rgba(50, 50, 50, 0.1);
    border-radius: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: rgba(50, 50, 50, 0.4);
    border-radius: 4px;
}
